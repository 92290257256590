// extracted by mini-css-extract-plugin
export var mobile = "766px";
export var tablet = "1025px";
export var desktop = "1368px";
export var blue300 = "#0929ae";
export var violet300 = "#4f2c98";
export var blue200 = "#1b3bc0";
export var pink200 = "#ef5b72";
export var bannerRoot = "Project-module--bannerRoot--2rAb0";
export var contentRoot = "Project-module--contentRoot--1-q9p";
export var image = "Project-module--image--36HMe";
export var title = "Project-module--title--3lN2Q";
export var projectAttachmentList = "Project-module--projectAttachmentList--2T67b";
export var attachmentParagraph = "Project-module--attachmentParagraph--2Pn4U";