import React from "react";

import ReactMarkdown from "react-markdown";
import { Banner } from "src/components/Banner";
import { Container } from "src/components/Container";
import { PageWrapper } from "src/components/PageWrapper";
import { Typography } from "src/components/Typography";
import type { UploadFile } from "src/types";

import * as styles from "./Project.module.scss";

interface ProjectAttachment {
  attachment: UploadFile[];
  Title: string;
  id: string;
  strapi_component: string;
}

export interface ProjectProps {
  title: string;
  description: string;
  slug: string;
  image: {
    width: number;
    height: number;
    localFile: {
      publicURL: string;
    };
  };
  Files: ProjectAttachment[];
  content: string;
  footer: string;
}

const Paragraph: React.FC = ({ children }) => (
  <Typography variant="p">{children}</Typography>
);

export const Project: React.FC<ProjectProps> = ({
  content,
  title,
  image,
                                                  /* eslint-disable */
  Files,
  footer,
}) => {
  return (
    <PageWrapper mobileNavProps={{ isCollorChangingOnScroll: false }}>
      <div>
        <Banner className={styles.bannerRoot}>
          <Container>
            <Typography variant="h3" className={styles.title}>
              {title}
            </Typography>
          </Container>
        </Banner>
        <Container rootClassName={styles.contentRoot}>
          {!image && (
            <img
              height={image.height}
              width={image.width}
              src={image.localFile.publicURL}
              className={styles.image}
            />
          )}
          <section>
            <ReactMarkdown
              skipHtml={false}
              components={{
                p: Paragraph,
              }}
            >
              {content}
            </ReactMarkdown>
          </section>

          <ul className={styles.projectAttachmentList}>
            {Files.map((file) => (
              <li key={file.id}>
                <Typography variant="p" className={styles.attachmentParagraph}>
                  <a
                    href={`${file.attachment[0].url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {file.Title}
                  </a>
                </Typography>
              </li>
            ))}
          </ul>

          <section>
            <ReactMarkdown
              skipHtml={false}
              components={{
                p: Paragraph,
              }}
            >
              {footer}
            </ReactMarkdown>
          </section>
        </Container>
      </div>
    </PageWrapper>
  );
};
