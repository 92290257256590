import React from "react";

import { graphql, PageProps } from "gatsby";
import { Project, ProjectProps } from "src/layouts/Project";

export interface StrapiEvents {
  strapiProjects: ProjectProps;
}

const ProjectPage = ({ data }: PageProps<StrapiEvents>) => {
  return <Project {...data.strapiProjects} />;
};

export const query = graphql`
  query ($id: String) {
    strapiProjects(id: { eq: $id }) {
      description
      content
      footer
      title
      slug
      image {
        width
        height
        localFile {
          publicURL
        }
      }
      Files
    }
  }
`;

export default ProjectPage;
